.popupbox{
  width:100%;
  height:100%;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
};

.popupbox[hidden]{
  display: none;
}

.popupbox-overlay{
  position:fixed;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  z-index:1002;
  background: none repeat scroll 0% 0% #000;
}

.popupbox-content {
  overflow: hidden;
}

.popupbox-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  z-index: 1003;
  position: relative;
}

.popupbox-titleBar {
  overflow: hidden;
  display: block;
  position: relative;
}

.popupbox-btn--close {
  z-index: 1004;
}

.popupbox[data-title='bottom'] .popupbox-content { order: 1 }
.popupbox[data-title='bottom'] .popupbox-titleBar { order: 2 }

/* ----- default theme ----- */

.popupbox-wrapper {
  border-radius: 3px;
  overflow: hidden;
  max-width: 80%;
  min-width: 300px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .9);
  background-color: white;
}

.popupbox-content {
  padding: 20px 24px 30px;
}

.popupbox-titleBar {
  font-weight: bold;
  font-size: 18px;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, .2);
  padding: 11px 37px 11px 24px;
  border-bottom: 1px #ccc solid;
}

.popupbox-btn--close {
  transition: all .5s;
  position: absolute;
  right: 11px;
  top: 11px;
  color: #c1c1c1;
  background: none;
  border: none;
  outline: none;
}

.popupbox-btn--close:hover {
  color: #000;
}

.popupbox.is-active {
  opacity: 1;
  pointer-events: auto;
}

.popupbox[data-title='bottom'] .popupbox-content { box-shadow: 0 1px 1px rgba(0, 0, 0, .3) }
.popupbox[data-title='bottom'] .popupbox-titleBar { box-shadow: none; border-top: 1px #ccc solid }
